import axios from 'axios';

import * as comm from '../utils/CommEmnu';

const http = axios.create({
    baseURL: comm.baseUrl,
});

// 设置请求拦截器
http.interceptors.request.use(
  (req) => {
    req.headers['Content-Type'] = 'application/json; charset=UTF-8';
    return req;
  },
  (error) => {
    return error;
  }
);

// 设置响应拦截器
http.interceptors.response.use(
  (res) => {

    return res;
  },
  (error) => {
    return error;
  }
);

export default http;
