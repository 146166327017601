import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from "@/axios/axiosConfig";
import store from "@/vuex/store";

Vue.prototype.$http = http;

http.interceptors.request.use(function (config) {
    // 向请求头加入User-Token
    config.withCredentials = true;

    const tokenUserInfo = localStorage.getItem('token')
    config.headers['token'] = tokenUserInfo;

    return config;
}, function (error) {
    return Promise.reject(error);
});

import SignCanvas from "sign-canvas";
Vue.use(SignCanvas);


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

//公共数据绑定在原型链上
Vue.use(ElementUI);
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
