import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
var store = new Vuex.Store({

    state: {


    },       //使用vuex中的数据
    mutations: {


    },   //使用vuex中的方法
    getters: {



    },     //  对state中的数据进行初步加工，返回的是加工过的数据
    actions: {}      //对vuex中的方法进行了加工，形成新的方法
})


export default store
