<template>
  <div>
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<style>

</style>
<script>


export default {
  name: "Floor",
  data(){
    return{

    }
  },
  methods:{

  }

}
</script>

<style  lang="scss" >
.el-scrollbar {
> .el-scrollbar__bar {
  opacity: 1 !important;
}
}

//body{
//  position: fixed;
//  bottom: 0;
//  overflow: scroll;
//  top: 0;
//  left: 0;
//  width: 100%;
//}

</style>
