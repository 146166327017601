<template>
  <div style="display: flex;flex-direction: column;align-content: center;align-content: center">


    <!--  底部-->
    <div
        style="margin-top: -40px;width: 100%;z-index: 999;border-radius: 30px;background-color: white;display: flex;flex-direction: row;justify-content: center;justify-items: center">
      <van-tabs  color="#039BE5"  :swipeable="true" :animated="true"
                style="margin-top: 10px;width: 93%">
        <van-tab title="信息提交">
          <el-form :inline-message="true" :rules="rules" ref="ruleForm1" :model="initForm" label-width="80px"
                   label-position="left"
                   style="">

            <h4>基本情况</h4>

            <div
                style="width: 100%;display: flex;flex-direction: column;align-items: center;margin-left: 0px;margin-top: 20px">
              <el-upload
                  accept="image/*"
                  v-loading="loading"
                  action="/safeConduct/v1/car/upload/drivingLicenseImage"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :before-upload="(file) => beforeAvatarUpload(file)"
              >
                <img v-if="path" :src="path" class="avatar">
                <div v-else class=" avatar-uploader-icon">+</div>
              </el-upload>
              <p>上传本人图片</p>
            </div>

            <el-form-item label="姓名" prop="parentPhone" label-width="300">
              <el-input type="text" v-model="initForm.name"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="parentPhone" label-width="300">
              <el-radio v-model="initForm.sex" label="male">男</el-radio>
              <el-radio v-model="initForm.sex" label="female">女</el-radio>
            </el-form-item>

          </el-form>
          <div style="min-width: 100%;align-items: center;display: flex;justify-content: right;margin-top: 30px">
            <el-button type="primary" @click="save" style="width: 100%;margin-bottom: 30px">提交</el-button>
          </div>
        </van-tab>

      </van-tabs>
    </div>


  </div>
</template>

<script>
import canvasResize from 'canvas-resize'
import * as clientUtils from "@/axios/ClientUtils";
export default {
  name: "zcList",
  data() {
    return {

      //基础表单
      initForm: {
        sex:"female",
        phone:"",
        birthDate:"",
        name:"",
        position:"",//职位
      },

      //表单校验规则
      rules: {

      },
      loading:false,
      path:""


    }
  },
  methods: {
    beforeAvatarUpload(file) {

      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {

        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }


      const that = this


      canvasResize(file, {
        crop: false,
        quality: 0.2,
        rotate: 0,
        callback(baseStr) {
          that.loading = true

          let obj = {"base64": baseStr}

          let updatejosn = JSON.stringify(obj);

          clientUtils.post("/upload/tengxun", updatejosn, function (res) {
            if (res.data.status === 1) {
              that.$message({
                message: '上传成功',
                type: 'success'
              });
              that.loading = false
              console.log("上传成功")
              that.path = res.data.path
            } else {
              that.$notify.error({
                title: "网络错误",
              });
              that.loading = false
            }
          })

        }
      })


      // return isJPG && isLt2M;
    },

    save(){
      let obj = {
        sex:this.initForm.sex,
        phone:this.initForm.phone,
        birthDate:this.initForm.birthDate,
        name:this.initForm.name,
        position:this.initForm.position,//职位
        path:this.path
      }

      const that = this
      clientUtils.post("/open/insert",obj,function (res){
       that.$notify.success({title:res.data.data})
        that.initForm.name = ''
        that.initForm.position = ''
        that.initForm.birthDate = ''
        that.initForm.phone =''
        that.path = ""
      })
    }

  },
  mounted() {

  },
  beforeDestroy() {

  },

}
//测试
</script>

<style scoped>

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #A93226;
}

.avatar-uploader-icon {
  border-radius: 20px;
  background-color: ghostwhite;
  font-size: 28px;
  color: #8c939d;
  align-content: center;
  align-items: center;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  border-radius: 10px;
  width: 178px;
  height: 178px;
  display: block;
}
</style>