import http from "@/axios/axiosConfig";


export function get(url, success) {
    const successCall = success;
    http.get(url).then((res) => {
            successCall(res);
    }).catch(function (error) {
        // 调用失败
        debug(error);
        // popErrorMessage('网络连接超时，请检查网络或稍后重试')
    })
}


function debug(data) {
    console.log(data)
}


export function post(url, parameters, success) {
    const successCall = success;
    http.post(url, parameters).then((res) => {
            successCall(res);
    }).catch(function (error) {
        // 调用失败
        debug(error);
    })
}
