import Vue from 'vue'
import VueRouter from 'vue-router'

import zcList from "../views/zcList";



Vue.use(VueRouter)

const routes = [

  {
    path: '/open',
    name: 'zcList',
    component: zcList
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
